/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, {
    useState, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
    string, object, shape, oneOfType, bool,
} from 'prop-types';
import useSSRMediaQuery from '../../../../helpers/hooks/useSSRMediaQuery';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const defaultQualities = {
    mobile: '80',
    tablet: '85',
    desktop: '90',
};

// reconcile browser functions
let eventLoopQueuer = setTimeout;
let eventLoopCleaner = clearTimeout;
// prefer idle callback when possible
if (typeof requestIdleCallback !== 'undefined') {
    /**
    * requestIdleCallback is a newer function but the one we want when
    * available. Its lower priority than setTimeout so more likely to
    * keep the main thread free.
    *
    * We handle this low priority by adding the "onClick" to the
    * placeholder component.
    */
    eventLoopQueuer = requestIdleCallback;
    eventLoopCleaner = cancelIdleCallback;
}

/**
 * Responsive Image Component
 * @param {string} path
 * @param {string} alt
 * @param {object} params
 * @param {object} breakpoints
 * @param {string} className
 * @param {boolean} isExternalSource
 */
const ResponsiveImage = ({
    path,
    alt,
    role,
    ariaLabel,
    params = {},
    breakpoints = {},
    className,
    style = {},
    isExternalSource,
    fullWidth = false,
}) => {
    const featureFlags = useSelector(getFeatureFlags);
    const ffIsProgressiveImagesEnabled = featureFlags['is-progressive-images-enabled'];
    // Need to strip out the quality, but maintain everything else
    const [qualityParams, setQualityParams] = useState(ffIsProgressiveImagesEnabled ? {
        desktop: params?.desktop?.replace(/quality=\d+/.exec(params?.desktop)?.[0], 'quality=1') || 'quality=1',
        tablet: params?.tablet?.replace(/quality=\d+/.exec(params?.tablet)?.[0], 'quality=1') || 'quality=1',
        mobile: params?.mobile?.replace(/quality=\d+/.exec(params?.mobile)?.[0], 'quality=1') || 'quality=1',
    } : {
        desktop: params?.desktop || 'quality=75',
        tablet: params?.tablet || 'quality=70',
        mobile: params?.mobile || 'quality=65',
    });
    const hqImageTimeout = useRef();
    const { desktop: desktopB = '1024px', tablet: tabletB = '640px', mobile: mobileB = '200px' } = breakpoints;
    const deviceType = useSSRMediaQuery(Object.entries(breakpoints) > 0 ? breakpoints : {
        mobile: '200px',
        tablet: '640px',
        desktop: '1024px',
    });
    const defaultParams = 'auto=webp&optimize={medium}'; // if no params & needs to be ?

    useEffect(() => {
        const src = `${path}${qualityParams?.[deviceType] ? `?${qualityParams?.[deviceType]}` : '?'}${qualityParams?.[deviceType] ? `&${defaultParams}` : defaultParams}`;
        if (src.indexOf('quality=1') !== -1 && typeof window !== 'undefined' && ffIsProgressiveImagesEnabled) {
            hqImageTimeout.current = eventLoopQueuer(() => {
                const newQuality = params[deviceType] || defaultQualities[deviceType];
                const newSrc = src.replace('quality=1', newQuality);
                const imageToLoad = new Image();
                imageToLoad.src = newSrc;
                imageToLoad.onload = () => {
                    // When image is loaded replace the image's src and set loading to false
                    setQualityParams({
                        ...qualityParams,
                        [`${deviceType}`]: newQuality,
                    });
                };
            });
        }
    }, [deviceType, hqImageTimeout, ffIsProgressiveImagesEnabled]);

    useEffect(() => () => {
        if (typeof window !== 'undefined' && ffIsProgressiveImagesEnabled && hqImageTimeout?.current) {
            eventLoopCleaner(hqImageTimeout.current);
        }
    }, [ffIsProgressiveImagesEnabled, hqImageTimeout]);

    /**
     * If things are coming from someone else, dont spam with
     * interally defined parameters in query.
     */
    if (isExternalSource) {
        return (
            <picture>
                <source type="image/jpeg" media={`(min-width: ${desktopB})`} srcSet={`${path}`} />
                <source type="image/jpeg" media={`(min-width: ${tabletB})`} srcSet={`${path}`} />
                <source type="image/jpeg" media={`(min-width: ${mobileB})`} srcSet={`${path}`} />
                {/* img fallback if browser doesn't support picture */}
                {role
                    ? (
                        <img style={style} className={className} src={`${path}`} alt={alt} role={role} aria-label={ariaLabel} />
                    ) : (
                        <img style={style} className={className} src={`${path}`} alt={alt} />
                    )}
            </picture>
        );
    }

    return (
        <picture style={{ width: (fullWidth ? '100%' : 'auto') }}>
            <source
                type="image/jpeg"
                media={`(min-width: ${desktopB})`}
                srcSet={`${path}${qualityParams.desktop ? `?${qualityParams.desktop}` : '?'}${qualityParams.desktop ? `&${defaultParams}` : defaultParams}`}
            />
            <source
                type="image/jpeg"
                media={`(min-width: ${tabletB})`}
                srcSet={`${path}${qualityParams.tablet ? `?${qualityParams.tablet}` : '?'}${qualityParams.tablet ? `&${defaultParams}` : defaultParams}`}
            />
            <source
                type="image/jpeg"
                media={`(min-width: ${mobileB})`}
                srcSet={`${path}${qualityParams.mobile ? `?${qualityParams.mobile}` : '?'}${qualityParams.mobile ? `&${defaultParams}` : defaultParams}`}
            />
            {/* img fallback if browser doesn't support picture */}
            {role
                ? (
                    <img
                        style={style}
                        className={className}
                        src={`${path}?${defaultParams}`}
                        alt={alt}
                        role={role}
                        aria-label={ariaLabel}
                    />
                ) : (
                    <img
                        style={style}
                        className={className}
                        src={`${path}?${defaultParams}`}
                        alt={alt}
                    />
                )}
        </picture>
    );
};

// TODO: Default params provided by default props

ResponsiveImage.propTypes = {
    path: string.isRequired,
    alt: string,
    role: string,
    ariaLabel: string,
    params: shape({
        desktop: string,
        tablet: string,
        mobile: string,
    }),
    breakpoints: shape({
        desktop: string,
        tablet: string,
        mobile: string,
    }),
    className: oneOfType([
        string,
        object,
    ]).isRequired,
    style: object,
    isExternalSource: bool,
    fullWidth: bool,
};

ResponsiveImage.defaultProps = {
    alt: '',
    role: '',
    ariaLabel: '',
    params: {},
    breakpoints: {},
    style: {},
    isExternalSource: false,
    fullWidth: false,
};

export default ResponsiveImage;
