/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { PureComponent } from 'react';
import { object, number } from 'prop-types';
import DesktopHPTiers from './Desktop/HomepageTiers';

class GraphqlHPTiers extends PureComponent {
    render() {
        const { data: { category_group = [], max_per_row = 2 } } = this.props;
        return <DesktopHPTiers data={category_group} maxPerRow={max_per_row} />;
    }
}
GraphqlHPTiers.propTypes = {
    data: object.isRequired,
    max_per_row: number,
};

GraphqlHPTiers.defaultProps = {
    max_per_row: 2,
};

export default (GraphqlHPTiers);
