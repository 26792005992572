/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Brands that use this component
// 1800-Flowers (18F)
// 1800-Baskets (18B)

// libraries
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
    array, object, func, number, string,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useLazyQuery } from '@apollo/react-hooks';
// helpers
import { getBrand } from '../../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { actions as tagManagerActions } from '../../../../../../../state/ducks/TagManager';
import findURL from '../../../../../../gql/queries/findURL';
import findCategoryPageByURL from '../../../../../../gql/queries/findCategoryPageByURL';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import hexToRgb from '../../../../../../helpers/contentstack/hexToRgb';

import LazyLoadWrapper from '../../../../GraphqlCommonComponents/LazyLoadWrapper/LazyLoadWrapper';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import { determineCollectionPrefixPath } from '../../../../../../helpers/determineInternationalizationPath';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.downMedium,
            margin: '10px auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upMedium,
            margin: '10px auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth.upLarge,
            margin: '10px auto',
        },
    },
    mainContainer: {
        backgroundColor: theme.palette.tiers?.container?.backgroundColor,
    },
    tierTitle: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        fontSize: 18,
        fontWeight: 400,
        paddingLeft: 20,
        backgroundColor: theme.palette.tiers?.title.backgroundColor,
        textAlign: theme.palette.tiers?.title.upSmall.textAlign,
        lineHeight: theme.palette.tiers?.title.lineHeight,
        color: theme.palette.tiers?.title.color,
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.9,
            fontSize: 14,
            padding: 0,
            textAlign: theme.palette.tiers?.title.downSmall.textAlign,
        },
    },
    insideCard: {
        margin: 5,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            '& a': {
                textDecoration: 'none',
            },
        },
    },
    reponsiveImage: {
        maxWidth: '100%',
        display: 'block',
    },
    card: {
        backgroundColor: theme.palette.tiers?.card.backgroundColor,
    },
    tierTitleFlower: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            position: 'relative',
            backgroundColor: theme.palette.white,
            color: theme.palette.common?.black,
            justifyContent: 'center',
            margin: '10px 0',
            fontWeight: 700,
        },
    },
    imageBlock: {
        [theme.breakpoints.down('sm')]: {
            borderRadius: '5px',
            boxShadow: theme.palette.uiShadow,
        },
    },
});
const HomePageTiers = ({
    data, brand: { domain = '', code = '' } = {}, classes, trackEvent, maxPerRow, presentation_family,
}) => {
    const getCard = (tier, isLast = false) => {
        const {
            link: { title = '', href = '' } = {},
            mobile_image = {},
            image,
            title_background_color,
            title_font_color,
            title_text_alignment,
            background_transparancy,
            title_padding,
            title_position,
            title_line_height,
            tracking_event_action,
            tracking_event_category,
            tracking_event_label,
        } = tier;
        // Apollo prefetching from mouseOver TODO mobile
        const FIND_URL_QUERY = findURL(domain, href);
        const FIND_CATEGORY_PAGE_QUERY = findCategoryPageByURL(domain, href);

        const [loadURL, { errorURL }] = useLazyQuery(FIND_URL_QUERY);
        const [loadCategory, { errorCategory }] = useLazyQuery(FIND_CATEGORY_PAGE_QUERY);

        if (errorURL || errorCategory) { mbpLogger.logError({ message: `Apollo pre-fetching error in: errorURL: ${errorURL} || errorCategory ${errorCategory}` }); }

        // Postition of title
        let titlePositionTop = 'auto';
        let titlePositionBottom = '0';
        switch (title_position) {
            case 'Top':
                titlePositionTop = '0';
                titlePositionBottom = 'auto';
                break;
            case 'Bottom':
                titlePositionTop = 'auto';
                titlePositionBottom = '0';
                break;
            case 'Middle':
                titlePositionTop = '42%';
                titlePositionBottom = '41%';
                break;
            default:
                titlePositionTop = 'auto';
                titlePositionBottom = '0';
        }
        const styleOverrides = {};
        if (title_background_color) styleOverrides.backgroundColor = hexToRgb(title_background_color, background_transparancy);
        if (title_font_color) styleOverrides.color = title_font_color;
        if (title_text_alignment) styleOverrides.textAlign = title_text_alignment;
        if (title_padding) styleOverrides.padding = title_padding;
        if (title_line_height) styleOverrides.lineHeight = title_line_height;
        styleOverrides.top = titlePositionTop;
        styleOverrides.bottom = titlePositionBottom;
        const getCollectionPath = () => {
            if (code === '08F') {
                return determineCollectionPrefixPath(tier.link.href, true);
            }
            return determineCollectionPrefixPath(tier.link.href, false);
        };
        const targetURL = presentation_family === 'growthbrand' ?  getCollectionPath() : tier.link.href;

        return (
            <Grid className={classes.card} key={title} item xs={isLast ? 12 : 12 / maxPerRow}>
                <div className={classes.insideCard}>
                    <Link
                        id={href}
                        to={targetURL}
                        onClick={() => trackEvent({
                            eventName: 'feature_interaction', eventCategory: tracking_event_category, eventAction: tracking_event_action, eventLabel: tracking_event_label,
                        })}
                        onMouseOver={() => {
                            loadURL();
                            loadCategory();
                        }}
                        onFocus={() => {
                            loadURL();
                            loadCategory();
                        }}
                        title={title}
                    >
                        <LazyLoadWrapper
                            config={{
                                once: true,
                                height: 425,
                                offset: 20,
                            }}
                        >
                            <ResponsiveImage className={presentation_family === 'growthbrand' ? `${classes.imageBlock} ${classes.reponsiveImage}` : classes.reponsiveImage} path={`${image ? image.url : mobile_image.url}`} params={{ desktop: 'quality=60', mobile: 'quality=50' }} alt={title} />
                            <span
                                className={presentation_family === 'growthbrand' ? `${classes.tierTitleFlower} ${classes.tierTitle}` : classes.tierTitle}
                                style={styleOverrides}
                            >
                                {title}
                            </span>
                        </LazyLoadWrapper>
                    </Link>
                </div>
            </Grid>
        );
    };
    let lastTier = null;
    if (data?.length % maxPerRow) {
        lastTier = data.pop();
    }
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={classes.container}>
            <Grid className={classes.mainContainer} container spacing={3}>
                {data?.map((tier) => (
                    getCard(tier)
                ))}
                {lastTier ? (
                    getCard(lastTier, true)
                ) : null}
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    presentation_family: getPresentationFamily(state),
});

HomePageTiers.propTypes = {
    classes: object.isRequired,
    data: array.isRequired,
    trackEvent: func.isRequired,
    brand: object.isRequired,
    maxPerRow: number,
    presentation_family: string.isRequired,
};

HomePageTiers.defaultProps = {
    maxPerRow: 2,
};

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(HomePageTiers);
